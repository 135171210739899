
import { Options, Vue } from 'vue-class-component';
import codemirror from 'codemirror';

import 'codemirror/lib/codemirror.css';

@Options({
  props: {
    title: String,
    value: [String, Object],
    textarea: Boolean,
    readonly: {
      type: Boolean,
      default: false,
    },
    hiddenLineNumbers: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    change: String,
  },
})
export default class CodeMirror extends Vue {
  declare $props: {
    title: string;
    value: string;
    readonly: boolean;
    hiddenLineNumbers: boolean;
  }

  declare $refs: {
    cm: HTMLDivElement;
  }

  public cm!: codemirror.Editor;

  private cmOptions: codemirror.EditorConfiguration = {
    tabSize: 2,
    mode: { name: 'text/javascript', json: true },
    lineNumbers: !this.$props.hiddenLineNumbers,
    readOnly: this.$props.readonly,
    autofocus: !this.$props.readonly,
    showCursorWhenSelecting: true,
  };

  private setCursorOnLastRow(): void {
    const numLastLine = this.cm.lastLine();
    const lengthRow = this.cm.lineInfo(numLastLine).text.length;

    this.cm.setCursor({ line: numLastLine + 1, ch: lengthRow });
  }

  private listenChangeCM(): void {
    if (!this.$props.readonly) {
      this.cm.on('change', (i) => { this.$emit('change', i.getValue()); });
    }
  }

  mounted(): void {
    this.cm = codemirror(this.$refs.cm, this.cmOptions);

    if (this.$props.value) {
      switch (typeof this.$props.value) {
        case 'object':
          this.cm.setValue(JSON.stringify(this.$props.value, null, 2));
          this.setCursorOnLastRow();
          break;
        default:
          this.cm.setValue(this.$props.value);
      }
    }

    this.listenChangeCM();
  }
}
